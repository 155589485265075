import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="cart-item-quantity"
export default class extends Controller {
  static targets = ['quantityField'];

  increase(e) {
    const target = e.target.parentNode.parentElement.querySelector('input');
    // const product = JSON.parse(target.dataset.product);
    const productId = target.dataset.productId;
    // const productQuantity = product.quantity;
    // const isProductAlwaysStocked = product.always_stocked;

    // if (!isProductAlwaysStocked && parseInt(productQuantity) <= 0) {
    //   return;
    // }

    const updatedQuantity = parseInt(target.value) + 1;

    target.setAttribute('value', updatedQuantity);

    document.getElementById(`${productId}-add-cart`).click();
  }

  decrease(e) {
    const target = e.target.parentNode.parentElement.querySelector('input');
    const productId = target.dataset.productId;
    const itemQuantity = parseInt(target.value);

    if (itemQuantity <= 0) {
      e.preventDefault();

      return;
    }

    const updatedQuantity = itemQuantity - 1;

    target.setAttribute('value', updatedQuantity);

    if (updatedQuantity > 0) {
      document.getElementById(`${productId}-add-cart`).click();
      e.preventDefault();
    }
  }

  submit(e) {
    const productId = e.target.dataset.productId;

    document.getElementById(`${productId}-add-cart`).click();
  }
}
