import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="edit-cart-item-quantity"
export default class extends Controller {
  static targets = ['quantityField'];

  increase(e) {
    const target = e.target.parentNode.parentElement.querySelector('input');
    const productId = target.dataset.productid;
    const price = + target.dataset.productprice;
    // const productQuantity = target.dataset.productquantity;
    // const isProductAlwaysStocked = target.dataset.alwaysstocked;
    // if (!isProductAlwaysStocked && parseInt(productQuantity) <= 0) {
    //   return;
    // }

    const updatedQuantity = parseInt(target.value) + 1;
    target.setAttribute('value', updatedQuantity);
    document.getElementById(`product-${productId}-total-price`).innerHTML = `$${price * updatedQuantity}`;
    document.getElementById(`${productId}`).value = updatedQuantity;
    document.getElementById(`update-edit-cart-${target.dataset.order}`).classList.remove("hidden");
  }

  decrease(e) {
    const target = e.target.parentNode.parentElement.querySelector('input');
    const productId = target.dataset.productid;
    const price = + target.dataset.productprice;
    const itemQuantity = parseInt(target.value);

    if (itemQuantity <= 1) {
      e.preventDefault();

      return;
    }

    const updatedQuantity = itemQuantity - 1;
    target.setAttribute('value', updatedQuantity);
    document.getElementById(`${productId}`).value = updatedQuantity;
    document.getElementById(`update-edit-cart-${target.dataset.order}`).classList.remove("hidden");
    if (updatedQuantity > 0) {
      document.getElementById(`product-${productId}-total-price`).innerHTML = `$${price * updatedQuantity}`;
    }

  }

  editorder(e) {
    const target = e.target.parentNode.parentElement.parentElement.querySelector('input');
    const productId = target.dataset.productid;
    document.getElementById(`product-${productId}-quantity`).classList.add("hidden");
    document.getElementById(`${productId}-edit-section`).classList.remove("hidden");
  }

  submitorder(e) {
    const orderId = e.target.dataset.order;
    document.getElementById(`update-edit-cart-${orderId}`).click();
    document.getElementById(`update-order-${orderId}`).classList.add("hidden");
  }
}
