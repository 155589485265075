import { Modal } from 'tailwindcss-stimulus-components';

export default class extends Modal {
  static targets = ['form'];

  closeIfSuccess({ detail: { success } }) {
    if (success) {
      this.formTarget.reset();
      this.close();
    }
  }

  reset() {
    this.formTarget.reset();
  }
}
