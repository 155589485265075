import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { email: String, token: String, magicNoRedirect: String };

  tryCustomProtocol() {
    // Custom protocol URL
    const urlParams = new URLSearchParams();
    urlParams.set('user[email]', this.emailValue);
    urlParams.set('user[token]', this.tokenValue);
    urlParams.set('user[magic_jwt]', 'true');
    const customUrl = `budsproduce://auth/sign-in?${urlParams.toString()}`;

    // Try to open the custom protocol
    window.location.href = customUrl;
  }

  openWebRedirect() {
    this.tryCustomProtocol();
    // Set a timeout to fallback to the HTTPS URL
    setTimeout(() => {
      const urlParams = new URLSearchParams();
      urlParams.set('user[email]', this.emailValue);
      urlParams.set('user[token]', this.tokenValue);
      urlParams.set('user[magic_jwt]', false);
      window.location.href = `${
        window.location.origin
      }/users/magic_link?${urlParams.toString()}`;
    }, 2000);
  }

  connect() {
    this.tryCustomProtocol();

    setTimeout(() => {
      this.element.hidden = false;
    }, 2000);
  }
}
